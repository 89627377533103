<script lang="ts">
    import { Input } from '$lib/components/ui/input';
    import { Label } from '$lib/components/ui/label';
    import { Button } from '$lib/components/ui/button';
    import { Separator } from '$lib/components/ui/separator';
    import { inertia, router } from '@inertiajs/svelte';
    import axios from 'axios';
    import { Turnstile } from 'svelte-turnstile';
    import { onMount } from 'svelte';
    import { getTheme } from '@/utility/theming/darkmode.ts';
    import {Checkbox} from "$lib/components/ui/checkbox";

    const siteKey = '0x4AAAAAAAIbV5smWueSWBbM';
    let turnstileToken = '';

    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');

    let sending = false;
    let values = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        terms: false,
    };

    function handleChange(e) {
        const key = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        values = {
            ...values,
            [key]: value,
        };
    }

    function onVerify(e: { detail: { token: string } }): void {
        turnstileToken = e.detail.token;
    }

    onMount(() => {
        const starsContainer = document.getElementById('stars-container');

        function createStar(x, y) {
            const star = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            star.setAttribute('viewBox', '0 0 24 24');
            star.setAttribute('width', '24');
            star.setAttribute('height', '24');
            star.classList.add('star');
            star.style.left = `${x}%`;
            star.style.top = `${y}%`;
            star.innerHTML = `<path d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z" fill="currentColor" />`;
            return star;
        }

        function createStarGrid() {
            starsContainer.innerHTML = '';
            const minDistance = 6.67;
            const starCount = 150;
            const placedStars = [];

            function distance(x1, y1, x2, y2) {
                return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
            }

            function isValidPosition(x, y) {
                return placedStars.every((star) => distance(x, y, star.x, star.y) >= minDistance);
            }

            for (let i = 0; i < starCount; i++) {
                let x, y;
                let attempts = 0;
                do {
                    x = Math.random() * 100;
                    y = Math.random() * 100;
                    attempts++;
                } while (!isValidPosition(x, y) && attempts < 100);

                if (attempts < 100) {
                    placedStars.push({ x, y });
                    const star = createStar(x, y);
                    starsContainer.appendChild(star);
                }
            }
        }

        function animateStars() {
            const stars = document.querySelectorAll('.star');
            const activeStars = document.querySelectorAll('.star.active');

            if (activeStars.length < Math.floor(stars.length / 16)) {
                stars.forEach((star) => {
                    if (!star.classList.contains('active') && Math.random() < 0.005) {
                        star.classList.add('active');
                        setTimeout(
                            () => {
                                star.classList.remove('active');
                            },
                            Math.random() * 3000 + 2000
                        );
                    }
                });
            }
        }

        createStarGrid();
        setInterval(animateStars, 100);
    });

    const handleSubmit = (event: Event) => {
        event.preventDefault();
        sending = true;

        if (!values.password || !values.password_confirmation) {
            launchToast('error', trans('Error'), 'Both password fields are required');
        } else if (values.password.trim() !== values.password_confirmation.trim()) {
            launchToast('error', trans('Error'), 'Passwords do not match');
        } else if (!values.terms) {
            launchToast('error', trans('Error'), 'Please agree to our Terms and conditions');
        } else if (!turnstileToken) {
            launchToast('error', trans('Error'), 'Please complete the Turnstile verification');
        } else {
            const registrationData = {
                ...values,
                turnstileToken,
            };

            axios
                .post('/register-post', registrationData)
                .then((response) => {
                    if (response.status === 200) {
                        launchToast('success', trans('Success'), 'Registration Success');
                        window.location.href = '/feed';
                    } else {
                        const errorMessage = response.data.message || 'An error occurred.';
                        launchToast('error', trans('Error'), errorMessage);
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response?.data.message || 'An error occurred.';
                    launchToast('error', trans('Error'), errorMessage);
                })
                .finally(() => {
                    sending = false;
                });
        }
        sending = false;
    };
</script>

<main class="loginScreen d-flex w-full min-h-screen items-center justify-center py-5">
    <div id="stars-container" class="absolute h-full"></div>

    <div class="loginFormContainer">
        <a href="/" class="d-block text-center">
            <img class="brand-logo mx-auto mb-3" alt="BMN Logo" src="/img/logo-white.png" />
        </a>
        <form on:submit="{handleSubmit}">
            <div class="mb-3 flex w-full max-w-lg flex-col gap-1.5">
                <Label for="name" class="dark:text-white">Name</Label>
                <Input
                    type="text"
                    id="name"
                    name="name"
                    on:change="{handleChange}"
                    required
                    placeholder="Enter Name"
                    class="dark:text-white"
                />
            </div>
            <div class="mb-3 flex w-full max-w-lg flex-col gap-1.5">
                <Label for="email" class="dark:text-white">Email Address</Label>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    on:change="{handleChange}"
                    placeholder="Enter Email Address"
                    class="dark:text-white"
                />
            </div>
            <div class="mb-3 flex w-full max-w-lg flex-col gap-1.5">
                <Label for="password" class="dark:text-white">Password</Label>
                <Input
                    type="password"
                    name="password"
                    on:change="{handleChange}"
                    required
                    id="password"
                    placeholder="Enter Password"
                    class="dark:text-white"
                />
            </div>
            <div class="mb-3 flex w-full max-w-lg flex-col gap-1.5">
                <Label for="cpassword" class="dark:text-white">Confirm Password</Label>
                <Input
                    type="password"
                    name="password_confirmation"
                    on:change="{handleChange}"
                    required
                    id="cpassword"
                    placeholder="Enter Confirm Password"
                    class="dark:text-white"
                />
            </div>
            <div class="mb-3 flex items-center space-x-2">
                <Checkbox bind:checked="{values.terms}" />
                <Label
                    id="terms-label"
                    for="terms"
                    class="my-0 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:text-white"
                >
                    I agree to the <a use:inertia href="/terms-and-conditions" class="underline">Terms and Conditions</a> and <a use:inertia  href="/privacy-policy" class="underline">Privacy Policy</a>
                </Label>
            </div>
            <Turnstile {siteKey} theme="{getTheme()}" on:turnstile-callback="{onVerify}" class="mb-3" />
            <Button class="mb-3 w-full text-white" type="submit" disabled="{sending}">Register</Button>
            <Separator class="my-3 bg-neutral-200 dark:bg-neutral-800" />
            <p class="text-center text-sm text-muted-foreground dark:text-white">
                Already have an account? <a
                    use:inertia
                    href="/login"
                    class=" text-gradient font-weight-bold bg-gradient-to-r from-bmn-400 to-bmn-500 hover:!from-bmn-400 hover:!to-bmn-700"
                    >Login</a
                >
            </p>
        </form>
    </div>
</main>
